<template>
  <div v-if="formAction" style="padding: 20px">
    <div v-if="formAction === 'select'">
      <div class="block">
        <img
          :src="contentFormInfo.logo_url || '/Five9Logo.jpg'"
          @error="handleAltImg"
          alt="logo"
          style="width: 125px; height: 125px; text-align: left; object-fit: contain "
        />
      </div>
      <div class="titles">{{ __("Name") }}</div>
      <div class="details">{{ contentFormInfo.sp_name }}</div>
      <div class="titles">{{ __("Service Provider ID") }}</div>
      <div class="details">{{ contentFormInfo.sp_id }}</div>
      <div class="titles">{{ __("Billing mode") }}</div>
      <div class="details">{{ contentFormInfo.billing_mode }}</div>
      <div class="titles">{{ __("Sub domain") }}</div>
      <div class="details">{{ contentFormInfo.subdomain }}</div>
      <div class="titles">{{ __("Service Provider Display Date Format") }}</div>
      <div class="details">
        {{
          contentFormInfo.display_date_format === ""
            ? "yyyy/MM/dd"
            : contentFormInfo.display_date_format
        }}
      </div>
      <div class="titles">{{ __("Service Provider TimeZone") }}</div>
      <div class="details">
        {{
          contentFormInfo.default_timezone === ""
            ? "UTC"
            : contentFormInfo.default_timezone
        }}
      </div>
      <div style="display: flex; padding-top: 20px">
        <el-dropdown @command="handleAction" class="allAcActionDropDown">
          <el-button type="primary">
            {{ __("Actions") }}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(command, index) in commandsToShow"
              :key="index"
              :command="command.command"
              :disabled="!canWrite"
            >
              <div
                style="display: flex; justify-content: flex-start; align-items: center"
              >
                <img
                  :src="getIconSVG(command.icon)"
                  alt
                  height="18px"
                  width="18px"
                  style="margin-right: 10px"
                />
                <span>{{ command.label }}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      fullscreen
      :show-close="false"
      top="6vh"
      destroy-on-close
    >
      <el-row type="flex">
        <el-col :span="12" :offset="6">
          <page-header :title="__('Service Provider')" :contentId="id" />
        </el-col>
      </el-row>
      <el-scrollbar :native="false">
        <el-form
          ref="contentForm"
          :rules="rules"
          :model="contentForm"
          label-position="top"
          size="large"
        >
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item :label="__('Name')" prop="sp_name" required>
                <el-input v-model="contentForm.sp_name"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item :label="__('Subdomain')" prop="subdomain">
                <el-input
                  v-model="contentForm.subdomain"
                  v-loading="isCheckingSubdomain"
                >
                  <template slot="append">.{{ appLocationHost }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item
                :label="__('Billing Mode')"
                prop="billing_mode"
                required
              >
                <el-radio-group v-model="contentForm.billing_mode">
                  <el-radio label="sp"></el-radio>
                  <el-radio label="ac"></el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item :label="__('Note')" prop="note">
                <el-input v-model="contentForm.note"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item label="" prop="display_date_format">
                <display-date-format-dropdown
                  v-model="contentForm.display_date_format"
                  @display-date-format-changed="displayDateFormatChanged"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item :label="__('Timezone')" prop="default_timezone">
                <timezone-dropdown
                  :default_timezone="contentForm.default_timezone"
                  @timezone-changed="handleTimezoneChange"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" v-if="showStatusPortalToggle">
            <el-col :span="12" :offset="6">
              <el-form-item prop="status_portal_enabled">
                <div slot="label">
                  {{ __("Status Portal Integration") }}
                  <el-popover
                    placement="top-start"
                    trigger="hover"
                    content=""
                    style="margin-left: 4px"
                  >
                    <p>
                      Enables or disables creation of status portal accounts for
                      account admins.
                    </p>
                    <p>
                      When enabled, all existing admin accounts will have a user
                      created in the status portal.
                    </p>
                    <p>
                      When disabled, all existing admin accounts will have their
                      user disabled in the status portal.
                    </p>
                    <i
                      slot="reference"
                      style="font-size: 0.938rem"
                      class="el-icon-info icon"
                    ></i>
                  </el-popover>
                </div>
                <el-switch
                  id="status_portal_toggle"
                  :value="contentForm.status_portal_enabled || false"
                  @change="handleStatusPortalIntegrationToggle"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item :label="__('Logo')" prop="logopath">
                <logo-uploader
                  :folder="folder"
                  :file-url="contentForm.logo_url"
                  @on-success="handleSuccess"
                  @on-error="handleError"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item>
                <save-button
                  type="primary"
                  :loading="isLoading"
                  @click="submitForm"
                  class="submitBtn"
                  v-show="this.canWrite()"
                  :primaryKey="id"
                  variant="CreateUpdate"
                />
                <el-button class="cancelBtn" @click="cancelForm">{{
                  __("Cancel")
                }}</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import { mapState, mapActions, mapGetters } from "vuex";
import LogoUploader from "@/components/uploaders/types/AvatarUploader";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import TimezoneDropdown from "../../../../components/TimezoneDropdown";
import _ from "lodash";
import { checkUniqueSubdomain } from "@/api/serviceproviders";
import { alphaNumericDashesOnly } from "@/utils/regex";
import DisplayDateFormatDropdown from "@/components/DisplayDateFormatDropdown";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    SaveButton,
    LogoUploader,
    TimezoneDropdown,
    DisplayDateFormatDropdown
  },
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  data() {
    let validateSubdomain = async (rule, value, callback) => {
      try {
        if (
          value &&
          (this.id === -1 ||
            (this.id === null && this.contentFormInfo.subdomain !== value))
        ) {
          this.isCheckingSubdomain = true;
          if (!alphaNumericDashesOnly.test(value)) {
            callback(
              new Error(
                __("Subdomain must contain only alphabets, letters and dashes.")
              )
            );
          }

          const res = await checkUniqueSubdomain({
            subdomain: value,
            sp_id: this.contentForm.sp_id
          });
          if (res.data.found) {
            callback(new Error(__("Subdomain already exists")));
          } else if (res.status !== 200) {
            callback(new Error(res.data.message));
          } else {
            callback();
          }
        }
      } catch (e) {
        callback(new Error(e.message));
      } finally {
        this.isCheckingSubdomain = false;
      }
    };

    let checkSelectedBillingMode = (rule, value, callback) => {
      // If the Billing Mode is not selected
      if (!this.contentForm.billing_mode) {
        callback(__("Billing Mode is required"));
      } else {
        callback();
      }
    };

    return {
      isSubmitting: false,
      folder: "logo",
      showStatusPortalToggle: false,
      commands: [
        {
          command: "Switch to service provider",
          rules: {},
          icon: "campaign_actions/icon_reset.svg",
          selectAction: false,
          label: __("Switch")
        },
        {
          command: "createOrEdit",
          rules: {},
          icon: "campaign_actions/icon_pencil.svg",
          label: __("Edit"),
          selectAction: false
        },
        {
          command: "delete",
          rules: {},
          icon: "campaign_actions/icon_trash_new.svg",
          label: __("Delete"),
          selectAction: false
        },
        {
          command: "flush cache",
          rules: {},
          icon: "campaign_actions/icon_clear_cache.svg",
          label: __("Clear cache"),
          selectAction: false
        }
      ],
      isCheckingSubdomain: false,
      rules: {
        sp_name: [
          {
            required: true,
            trigger: "blur",
            message: __("Service Provider Name is required")
          }
        ],
        subdomain: [
          {
            trigger: "blur",
            validator: validateSubdomain
          }
        ],
        billing_mode: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: checkSelectedBillingMode
          }
        ]
      }
    };
  },

  computed: {
    ...mapGetters("app", {
      appLocationHost: "appLocationHost"
    }),

    ...mapState("serviceproviders", {
      isLoading: state => state.loading
    }),
    commandsToShow() {
      return _.filter(this.commands, command => {
        if (_.isEmpty(command.rules)) {
          return true;
        }
        return _.every(command.rules, (rule, key) => {
          return rule.includes(this.contentForm[key].toLowerCase());
        });
      });
    }
  },
  watch: {
    contentForm: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal.sp_id === oldVal.sp_id) {
          return;
        }

        this.showStatusPortalToggle = false;
        this.showFeature("studio.status-portal-integration.temp", {
          sp_id: newVal.sp_id
        }).then(show => (this.showStatusPortalToggle = show));
      }
    }
  },
  methods: {
    ...mapActions("serviceproviders", {
      createServiceProvider: "createServiceProvider",
      updateServiceProvider: "updateServiceProvider",
      deleteContentMethod: "deleteServiceProvider",
      undoDeleteContent: "undoDeleteServiceProvider",
      getFormContents: "getFormContents",
      flushCache: "flushCache"
    }),

    ...mapActions("upload", {
      deleteLogo: "deleteLogo"
    }),

    ...mapActions("app", {
      changeUserSelectedServiceProviderId:
        "changeUserSelectedServiceProviderId",
      changeUserSelectedAccountId: "changeUserSelectedAccountId",
      generateUserAccountSwitcherOptions: "generateUserAccountSwitcherOptions"
    }),

    getIconSVG(name) {
      return require("@/assets/icons/" + name);
    },

    handleAltImg(event) {
      event.target.src = "/Five9Logo.jpg";
    },

    handleSuccess(res) {
      console.log(res.url);
      this.contentForm.logo_url = res.url;
      this.contentForm.logopath = res.path;
      this.uploadedFilePath = res.path;
    },

    async handleTimezoneChange(timezone) {
      this.contentForm.default_timezone = timezone;
    },

    handleStatusPortalIntegrationToggle(value) {
      let action = __("enable");

      if (value === false) {
        action = __("disable");
      }

      this.$confirm(
        __(
          "Are you sure you want to :action status portal integration? This will :action status portal accounts for all account admins",
          { action }
        ),
        "Warning",
        {
          confirmButtonText: __("Yes"),
          cancelButtonText: __("No"),
          type: "warning"
        }
      )
        .then(
          () =>
            (this.contentForm.status_portal_enabled = !this.contentForm
              .status_portal_enabled)
        )
        .catch(() => {});
    },

    handleError(err) {
      console.log(err);
    },

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process =
            this.id === -1
              ? this.createServiceProvider
              : this.updateServiceProvider;
          this.contentForm.sp_status = 1;

          process(this.contentForm)
            .then(data => {
              this.contentFormKey = this.contentFormKey + !this.id ? 0 : 1;
              this.isSubmitting = false;

              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("Service Provider added successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Service Provider updated successfully")
                  });

              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },

    cancelForm() {
      if (this.id === -1) {
        this.deleteLogo({ path: this.uploadedFilePath })
          .then(() => {
            this.handleCancel();
          })
          .catch(err => {
            console.log(err);
            this.handleCancel();
          });
      }
      this.handleCancel();
    },
    handleSwitch() {
      this.changeUserSelectedServiceProviderId(this.contentForm.sp_id);
      this.changeUserSelectedAccountId("all");
      this.generateUserAccountSwitcherOptions();
    },
    handleAction(action) {
      if (action === "delete") {
        this.$confirm(
          __("This will permanently delete the Service Provider. Continue?"),
          "Warning",
          {
            confirmButtonText: __("Delete"),
            cancelButtonText: __("Cancel"),
            type: "warning"
          }
        ).then(() => {
          this.handleDelete(this.contentForm)
            .then(() => {
              this.$message({
                type: "success",
                message: __("Service Provider Deleted")
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: __("Cancelled")
              });
            });
        });
      } else if (action === "createOrEdit") {
        this.handleEdit();
      } else if (action === "flush cache") {
        this.handleFlushCache(this.contentForm);
      } else {
        this.handleSwitch();
      }
    },

    handleFlushCache(contentForm) {
      this.flushCache(contentForm)
        .then(() => {
          this.$message.success("Cache cleared");
        })
        .catch(err => {
          this.$message.error(err.message || "Failed to clear cache");
        });
    },

    /**
     * handle display date format changed
     * @param displayDateFormat
     */
    displayDateFormatChanged(displayDateFormat) {
      this.contentForm.display_date_format = displayDateFormat;
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

::v-deep .account-form-dialog {
  max-height: 90vh !important;
}
.allAcActionDropDown {
  color: white;
  background: $content-theme-color;
  border-radius: 6px;
  margin-top: 20px;

  ::v-deep button {
    background: $content-theme-color;
    border-color: transparent;
    height: 44px;
    &:disabled {
      color: white;
      //background-color: mix(white, $content-theme-color, 50%);
      border-color: $content-theme-outline-color !important;
    }
  }

  &:hover {
    ::v-deep button {
      background: $content-theme-hover-color;
    }
  }
}
</style>
