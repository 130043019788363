<script>
import { mapState } from "vuex";
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";

export default {
  name: "ServiceProvidersIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("serviceproviders", {
      contents: state => state.serviceProviders
    })
  },

  methods: {
    initContent() {
      return {
        sp_name: "",
        default_timezone: "",
        logopath: "",
        logo_url: "",
        status_portal_enabled: false
      };
    }
  },

  mounted() {
    this.contentType = "ServiceProvider";
    this.primaryKey = "sp_id";
    this.scope = "sys";
  }
};
</script>

<style lang="scss" scoped></style>
